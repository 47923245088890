<template>
  <div class="col-12 g c col-lg-7">
    <div class="card">
      <div class="card-header text-center">
        <h4>
          <i class="fa fa-plus"></i>
          إضافة باص جديد
        </h4>
      </div>
      <div class="card-body">
        <div class="form-group">
          <h5 for="">العنوان (إسم الباص او رقمه)</h5>
          <input type="text" class="form-control" v-model="title" />
        </div>
        <div class="form-group">
          <h5 for="">التفاصيل (اختياري)</h5>
          <textarea
            class="form-control"
            name=""
            id=""
            v-model="details"
            rows="3"
          ></textarea>
        </div>
        <div class="form-group">
          <h5 for="">القسم</h5>
          <select class="form-control" name="" v-model="section_id" id="">
            <template v-for="section in sections">
              <option :value="section._id" :key="section._id">
                {{ section.title }}
              </option>
            </template>
          </select>
        </div>
        <div class="form-group">
          <h5 for="">خط السير الافتراضي (يتغير عند بدء الرحلة)</h5>
          <select class="form-control" name="" v-model="group_id" id="">
            <template v-for="group in groups">
              <option :value="group._id" :key="group._id">
                {{ group.title }}
              </option>
            </template>
          </select>
        </div>
        <div class="col-12 text-center">
          <button class="btn btn-relief-success" @click="add()">
            إضافة الباص <i class="fa fa-arrow-left"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      title: null,
      details: null,
      group_id: null,
      user: JSON.parse(localStorage.getItem("user")),
      groups: [],
      sections: [],
      section_id: null,
    };
  },
  created() {
    checkPer(this, "bus", "add");
    var g = this;
    $.post(api + "/admin/groups/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.groups = JSON.parse(r).response;
    });
    $.post(api + "/admin/sections/list", {
      jwt: g.user.jwt,
    }).then(function (r) {
      g.sections = JSON.parse(r).response;
    });
  },
  methods: {
    add() {
      var g = this;
      $.post(api + "/admin/bus/add", {
        jwt: g.user.jwt,
        title: g.title,
        details: g.details,
        group_id: g.group_id,
        section_id: g.section_id,
      })
        .then(function (e) {
          e = JSON.parse(e);
          if (e.status != 100) {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: e.response,
                icon: "TimesIcon",
                variant: "danger",
              },
            });
          } else {
            g.$toast({
              component: ToastificationContent,
              props: {
                title: "تم اضافة الباص بنجاح",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            g.details = null;
            g.title = null;
            g.group_id = null;
          }
        })
        .catch(function () {
          g.$toast({
            component: ToastificationContent,
            props: {
              title: "حدث خطأ",
              icon: "TimesIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>